// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feedback-tsx": () => import("./../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-fridge-tsx": () => import("./../src/pages/my-fridge.tsx" /* webpackChunkName: "component---src-pages-my-fridge-tsx" */),
  "component---src-pages-recipe-suggestions-tsx": () => import("./../src/pages/recipe-suggestions.tsx" /* webpackChunkName: "component---src-pages-recipe-suggestions-tsx" */),
  "component---src-pages-search-ingredients-tsx": () => import("./../src/pages/search-ingredients.tsx" /* webpackChunkName: "component---src-pages-search-ingredients-tsx" */)
}

